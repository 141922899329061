import React from "react";
import PropTypes from "prop-types";
import TokenFpProvider from "../../../Services/TokenFpDataProvider";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import {Form, Grid, Icon} from "semantic-ui-react";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import ContingentButton from "../../ContingentButton";

export default class TokenFpMasterDetailView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            freePreviewData: {},
            selectedProperty: "",
            startTime: new Date().getTime(),
            endTime: new Date().getTime(),
            saving: false
        };

        TokenFpProvider.init({baseURL: process.env.TOKEN_FP_URL});
    }

    componentWillUnmount() {
        if (this.jsoneditor) {
            this.jsoneditor.destroy();
        }
    }

    componentDidMount() {
        this.getMetadata();
        this.jsoneditor = new JSONEditor(this.container, {mode: "text"});
    }

    getMetadata = () => {
        TokenFpProvider.getMetadata().then(payload => {
            payload.json().then(freeViewPayload => {
                if (freeViewPayload.hasOwnProperty("auth")) {
                    if (freeViewPayload.auth.error) {
                        this.props.toast("Error", freeViewPayload.auth.error.message, "error");
                    }
                } else {
                    this.setState({freePreviewData: freeViewPayload.free_preview});
                }
            });
        });
    }

    filterOnPermissions = () => {
        let permissions = this.props.user.hasOwnProperty("authz") && Array.isArray(this.props.user.authz) ? this.props.user.authz : [];
        let filteredList = [];

        try {
            if (permissions.indexOf('urn:all:aspen-power') !== -1) {
                console.log("(filterOnPermissions) Master permission detected");

                Object.keys(this.state.freePreviewData).sort().forEach(data => {
                    filteredList.push({
                        value: data,
                        label: data
                    });
                });
            } else {
                const scopes = [];
                for (const permission of this.props.permissions) {
                    if (permission.service === this.props.service && permission.module === this.props.module && Object.keys(this.state.freePreviewData).includes(permission.scope)) {
                        scopes.push(permission.scope);
                    }
                }

                scopes.sort();
                for (const scope of scopes) {
                    filteredList.push({
                        value: scope,
                        label: scope
                    });
                }
            }
        } catch (error) {
            this.props.toast('Message', error.toString(), "error");
        }

        return filteredList;
    };

    handleReactSelect = selectData => {
        console.log(`(handleReactSelect) Started, selectData: ${JSON.stringify(selectData)}`);
        let freeViewProp = selectData.value;
        let startTime = this.state.freePreviewData[freeViewProp].startTime * 1000;
        let endTime = this.state.freePreviewData[freeViewProp].endTime * 1000;

        let extraData =
            this.state.freePreviewData.hasOwnProperty(freeViewProp) && this.state.freePreviewData[freeViewProp].hasOwnProperty("extraData") ?
                this.state.freePreviewData[freeViewProp].extraData : "";

        this.setState({
            selectedProperty: selectData,
            startTime: startTime,
            endTime: endTime,
        });

        this.jsoneditor.set(extraData);
    };

    saveMetadata = () => {
        try {
            let extraData = this.jsoneditor.get();
            let selectedProperty = this.state.selectedProperty.value;
            const startTime = parseInt(this.state.startTime / 1000);
            const endTime = parseInt(this.state.endTime / 1000);
            const freePreviewData = Object.assign({}, {
                free_preview: Object.assign({},
                    this.state.freePreviewData,
                    {
                        [selectedProperty]: Object.assign({},
                            this.state.freePreviewData[selectedProperty],
                            {extraData, startTime, endTime}
                        )
                })
            });
            console.log("Start time: ", startTime, "end time: ", endTime);
            console.log(`(saveMetadata) propertyData: ${JSON.stringify(freePreviewData)}`);
            this.setState({saving: true}, () => {
                TokenFpProvider.setMetadata(freePreviewData).then(payload => {
                    payload.json().then(json => {
                        console.log("TokenFpMasterDetailView.saveMetadata: response: ", json);
                        this.props.toast("Message", "Token EFP updated: success", "success");
                    });
                }).finally(() => {
                    this.setState({saving: false});
                });
            });

        } catch (error) {
            console.log(`(saveMetadata) Error saving metadata: ${error}`);
            this.props.toast("Error", error.toString(), "error");
        }
    };

    render() {
        return (
            <Grid>
              <Grid.Column className="masterContainer">
                  <Grid columns={2}>
                      <Grid.Column>
                          <Form>
                              {
                                  this.state.freePreviewData &&
                                      <Form.Group widths="equal">
                                          <Form.Field>
                                              <label>Select Property</label>
                                              <Select
                                                  value={this.state.selectedProperty}
                                                  onChange={this.handleReactSelect}
                                                  options={this.filterOnPermissions()}
                                              />
                                          </Form.Field>
                                      </Form.Group>
                              }
                              {
                                  this.state.selectedProperty &&
                                      <Form.Group widths="equal">
                                          <Form.Field>
                                              <label>Start Date</label>
                                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                  <DateTimePicker
                                                      inputVariant="outlined"
                                                      fullWidth
                                                      value={this.state.startTime}
                                                      format="MM/dd/y HH:mm"
                                                      onChange={date => this.setState({startTime: new Date(date).getTime()})}
                                                  />
                                              </MuiPickersUtilsProvider>
                                          </Form.Field>
                                          <Form.Field>
                                              <label>End Date</label>
                                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                  <DateTimePicker
                                                      inputVariant="outlined"
                                                      fullWidth
                                                      value={this.state.endTime}
                                                      format="MM/dd/y HH:mm"
                                                      onChange={date => this.setState({endTime: new Date(date).getTime()})}
                                                  />
                                              </MuiPickersUtilsProvider>
                                          </Form.Field>
                                      </Form.Group>
                              }
                              {
                                  this.state.selectedProperty &&
                                  <Grid.Row style={{paddingBottom: "3ch"}}>
                                      <p>
                                          * All times are displayed in your local time, but registered as UTC (GMT+0000)
                                      </p>
                                      <p>
                                          ** If you want to close the Free Preview window as fast as possible, please set the
                                          End Time to
                                          +5 minutes from now to allow for a graceful shutdown of the window.
                                      </p>
                                  </Grid.Row>
                              }
                              {
                                  this.state.selectedProperty &&
                                  <Form.Group>
                                      <Form.Field>
                                          <ContingentButton
                                            onClick={this.saveMetadata}
                                            service={this.props.service}
                                            module={this.props.module}
                                            user={this.props.user}
                                            scope={this.state.selectedProperty.value}
                                            allPermissions={this.props.permissions}
                                            icon
                                          ><Icon name={!this.state.saving ? "save" : "spinner"} loading={this.state.saving}/>&nbsp;&nbsp;Save Metadata</ContingentButton>
                                      </Form.Field>
                                  </Form.Group>
                              }
                          </Form>
                      </Grid.Column>
                      <Grid.Column>
                          <Form>
                              <Form.Field>
                                  <label>Extra Data</label>
                              </Form.Field>
                          </Form>
                          <div style={{height: "300px"}} ref={elem => this.container = elem}/>
                      </Grid.Column>
                  </Grid>
              </Grid.Column>
          </Grid>
        );
    }
}

TokenFpMasterDetailView.propTypes = {
    toast: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    service: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    permissions: PropTypes.array.isRequired,
    userPermissions: PropTypes.array.isRequired
};
