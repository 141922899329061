import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Button, Checkbox, Container, Divider, Dropdown, Flag, Form, Icon, Input, Label, Message, Segment, TextArea, Modal, Popup} from "semantic-ui-react";
import DateFnsUtils from "@date-io/date-fns";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import CountryCodes from "../../../Utils/CountryCodes";
import cidrRegex from "cidr-regex";
import ipRegex from "ip-regex";
import ContingentButton from "../../ContingentButton";

const CIDR_REGEX = cidrRegex({exact: true});
const IP_REGEX = ipRegex({exact: true, includeBoundaries: true})

export default function TokenGeofencingDetailView(props) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [allowed, setAllowed] = useState(true);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [remainingCountryOptions, setRemainingCountryOptions] = useState([]);
    const [countriesToAdd, setCountriesToAdd] = useState([]);
    const [remainingMvpdOptions, setRemainingMvpdOptions] = useState([]);
    const [mvpds, setMvpds] = useState({});
    const [mvpdsToAdd, setMvpdsToAdd] = useState([]);
    const [selectedMvpds, setSelectedMvpds] = useState([]);
    const [remainingTokenNetworkOptions, setRemainingTokenNetworkOptions] = useState([]);
    const [tokenNetworks, setTokenNetworks] = useState({});
    const [tokenNetworksToAdd, setTokenNetworksToAdd] = useState([]);
    const [selectedTokenNetworks, setSelectedTokenNetworks] = useState([]);
    const [selectedTokenProducts, setSelectedTokenProducts] = useState([]);
    const [tokenProductToAdd, setTokenProductToAdd] = useState("");
    const [selectedAssetProducts, setSelectedAssetProducts] = useState([]);
    const [assetProductToAdd, setAssetProductToAdd] = useState("");
    const [requestors, setRequestors] = useState([]);
    const [requestorToAdd, setRequestorToAdd] = useState("");
    const [medias, setMedias] = useState([]);
    const [paths, setPaths] = useState([]);
    const [mediaToAdd, setMediaToAdd] = useState("");
    const [pathToAdd, setPathToAdd] = useState("");
    const [appIdNetworks, setAppIdNetworks] = useState([]);
    const [remainingAppIdNetworks, setRemainingAppIdNetworks] = useState([]);
    const [selectedAppIdNetworks, setSelectedAppIdNetworks] = useState([]);
    const [appIdNetworksToAdd, setAppIdNetworksToAdd] = useState([]);
    const [ips, setIps] = useState([]);
    const [ipToAdd, setIpToAdd] = useState("");
    const [assetNetworks, setAssetNetworks] = useState({});
    const [selectedAssetNetworks, setSelectedAssetNetworks] = useState([]);
    const [remainingAssetNetworks, setRemainingAssetNetworks] = useState([]);
    const [assetNetworksToAdd, setAssetNetworksToAdd] = useState([]);
    const [assetEntitlements, setAssetEntitlements] = useState([]);
    const [assetEntitlementToAdd, setAssetEntitlementToAdd] = useState("");
    const [tokenEntitlements, setTokenEntitlements] = useState([]);
    const [tokenEntitlementToAdd, setTokenEntitlementToAdd] = useState("");
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [initialGeofencingSettings, setInitialGeofencingSettings] = useState({});
    const [deletionModalOpen, setDeletionModalOpen] = useState(false);

    useEffect(function onSelectedGeofenceChange() {
        setInitialGeofencingSettings(Object.assign({
            name: "",
            description: "",
            allowed: true,
            country: [],
            ip: [],
            media: [],
            path: [],
            mvpd: [],
            requestor: [],
            appidnetwork: [],
            assetnetwork: [],
            tokennetwork: [],
            assetentitlement: [],
            tokenentitlement: [],
            assetproduct: [],
            tokenproduct: [],
            start: null,
            end: null
        }, props.selectedGeofence));
    }, [JSON.stringify(props.selectedGeofence)]);

    useEffect(function onInitialGeofencingSettingsUpdate() {
        console.log("initial geofencing settings: ", initialGeofencingSettings);
        if (Object.keys(initialGeofencingSettings).length > 0) {
            const updatedSelectedCountries = [];
            const updatedSelectedTokenNetworks = [];
            const updatedSelectedTokenProducts = [];
            const updatedSelectedMvpds = [];

            setName(initialGeofencingSettings.name);
            setAllowed(initialGeofencingSettings.allowed);
            setDescription(initialGeofencingSettings.description);

            for (const country of initialGeofencingSettings.country) {
                updatedSelectedCountries.push({id: country, displayName: CountryCodes[country]});
            }
            setSelectedCountries(updatedSelectedCountries);

            if (initialGeofencingSettings.hasOwnProperty("tokennetwork")) {
                for (const network of initialGeofencingSettings.tokennetwork) {
                    updatedSelectedTokenNetworks.push({id: network, displayName: tokenNetworks[network]});
                }
            }

            if (initialGeofencingSettings.hasOwnProperty("network")) {
                for (const network of initialGeofencingSettings.network) {
                    updatedSelectedTokenNetworks.push({id: network, displayName: tokenNetworks[network]});
                }
            }
            setSelectedTokenNetworks(updatedSelectedTokenNetworks);

            for (const mvpd of initialGeofencingSettings.mvpd) {
                updatedSelectedMvpds.push(mvpd);
            }
            setSelectedMvpds(updatedSelectedMvpds);

            if (initialGeofencingSettings.hasOwnProperty("tokenproduct")) {
                for (const product of initialGeofencingSettings.tokenproduct) {
                    updatedSelectedTokenProducts.push(product);
                }
            }

            if (initialGeofencingSettings.hasOwnProperty("product")) {
                for (const product of initialGeofencingSettings.product) {
                    updatedSelectedTokenProducts.push(product);
                }
            }
            setSelectedTokenProducts(updatedSelectedTokenProducts);

            setSelectedAssetProducts(Array.from(initialGeofencingSettings.assetproduct));
            setRequestors(Array.from(initialGeofencingSettings.requestor));
            setMedias(Array.from(initialGeofencingSettings.media));
            setPaths(Array.from(initialGeofencingSettings.path));
            setSelectedAppIdNetworks(Array.from(initialGeofencingSettings.appidnetwork));
            setIps(Array.from(initialGeofencingSettings.ip));
            setSelectedAssetNetworks(Array.from(initialGeofencingSettings.assetnetwork));
            setAssetEntitlements(Array.from(initialGeofencingSettings.assetentitlement));
            setTokenEntitlements(Array.from(initialGeofencingSettings.tokenentitlement));

            if (initialGeofencingSettings.start !== null) {
                setStart(new Date(initialGeofencingSettings.start * 1000));
            } else {
                setStart(initialGeofencingSettings.start);
            }

            if (initialGeofencingSettings.end !== null) {
                setEnd(new Date(props.selectedGeofence.end * 1000));
            } else {
                setEnd(initialGeofencingSettings.end);
            }
        }
    }, [JSON.stringify(initialGeofencingSettings)]);

    useEffect(function checkForUnsavedChanges() {
        if (Object.keys(initialGeofencingSettings).length < 1) {
            props.setIsSelectedGeolocationSettingUpdated(false);
            return;
        }

        const isNameUpdated = name !== initialGeofencingSettings.name;
        const isDescriptionUpdated = description !== initialGeofencingSettings.description;
        const isAllowedUpdated = allowed !== initialGeofencingSettings.allowed;
        const countryIds = [];
        for (const country of selectedCountries) {
            countryIds.push(country.id);
        }
        const areCountriesUpdated = areListsDifferent(countryIds, initialGeofencingSettings.country);
        const areMvpdsUpdated = areListsDifferent(selectedMvpds, initialGeofencingSettings.mvpd);
        const areIpsUpdated = areListsDifferent(ips, initialGeofencingSettings.ip);
        const areMediaUpdated = areListsDifferent(medias, initialGeofencingSettings.media);
        const arePathsUpdated = areListsDifferent(paths, initialGeofencingSettings.path);
        const areRequestorsUpdated = areListsDifferent(requestors, initialGeofencingSettings.requestor);
        const areAppIdNetworksUpdated = areListsDifferent(selectedAppIdNetworks, initialGeofencingSettings.appidnetwork);
        const tokenNetworkIds = [];
        for (const network of selectedTokenNetworks) {
            tokenNetworkIds.push(network.id);
        }
        const areTokenNetworksUpdated = areListsDifferent(tokenNetworkIds, initialGeofencingSettings.tokennetwork);
        const areAssetNetworksUpdated = areListsDifferent(selectedAssetNetworks, initialGeofencingSettings.assetnetwork);
        const areAssetProductsUpdated = areListsDifferent(selectedAssetProducts, initialGeofencingSettings.assetproduct);
        const areTokenProductsUpdated = areListsDifferent(selectedTokenProducts, initialGeofencingSettings.tokenproduct);
        const areAssetEntitlementsUpdated = areListsDifferent(assetEntitlements, initialGeofencingSettings.assetentitlement);
        const areTokenEntitlementsUpdated = areListsDifferent(tokenEntitlements, initialGeofencingSettings.tokenentitlement);
        const startAsNumber = start !== null ? Date.parse(start.toString()) / 1000 : null;
        const isStartUpdated = startAsNumber !== initialGeofencingSettings.start;
        const endAsNumber = end !== null ? Date.parse(end.toString()) / 1000 : null;
        const isEndUpdated = endAsNumber !== initialGeofencingSettings.end;

        props.setIsSelectedGeolocationSettingUpdated(isNameUpdated || isDescriptionUpdated || isAllowedUpdated || areCountriesUpdated || areMvpdsUpdated || areIpsUpdated ||
            areMediaUpdated || arePathsUpdated || areRequestorsUpdated || areAppIdNetworksUpdated || areTokenNetworksUpdated || areAssetNetworksUpdated || areAssetProductsUpdated ||
            areTokenProductsUpdated || areTokenNetworksUpdated || areAssetEntitlementsUpdated || areTokenEntitlementsUpdated || isStartUpdated || isEndUpdated);
    },[
        JSON.stringify(initialGeofencingSettings), name, description, allowed, JSON.stringify(selectedCountries), JSON.stringify(selectedMvpds),
        JSON.stringify(selectedTokenNetworks), JSON.stringify(selectedAppIdNetworks), JSON.stringify(selectedAssetNetworks), JSON.stringify(selectedAssetProducts),
        JSON.stringify(selectedTokenProducts), JSON.stringify(assetEntitlements), JSON.stringify(tokenEntitlements), JSON.stringify(requestors),
        JSON.stringify(medias), JSON.stringify(paths), JSON.stringify(ips), start, end
    ]);

    const areListsDifferent = (list1, list2) => {
        if (!Array.isArray(list1)) {
            list1 = [];
        }

        if (!Array.isArray(list2)) {
            list2 = [];
        }
        let areDifferent = false;
        for (const item of list1) {
            if (areDifferent) {
                break;
            }

            if (!list2.includes(item)) {
                areDifferent = true;
            }
        }

        for (const item of list2) {
            if (areDifferent) {
                break;
            }
            if (!list1.includes(item)) {
                areDifferent = true;
            }
        }
        return areDifferent;
    }

    useEffect(function updateRemainingCountries() {
        const updatedRemainingCountries = [];
        for (const code of Object.keys(CountryCodes)) {
            if (!selectedCountries.map(country => country.id).includes(code)) {
                updatedRemainingCountries.push({key: code, text: `[${code}] ${CountryCodes[code]}`, value: code, flag: code});
            }
        }
        setRemainingCountryOptions(updatedRemainingCountries);
    }, [JSON.stringify(selectedCountries)]);

    useEffect(function updateMvpds() {
        const updatedMvpds = {};
        if (props.mvpds) {
            for (const mvpd of Object.keys(props.mvpds)) {
                updatedMvpds[mvpd.toLowerCase()] = props.mvpds[mvpd];
            }
        }
        setMvpds(updatedMvpds);
    }, [JSON.stringify(props.mvpds)]);

    useEffect(function updateTokenAndAssetNetworks() {
        const updatedNetworks = {};
        if (props.tokenNetworks) {
            for (const network of Object.keys(props.tokenNetworks)) {
                updatedNetworks[network.toLowerCase()] = props.tokenNetworks[network];
            }
        }
        setTokenNetworks(updatedNetworks);
        setAssetNetworks(updatedNetworks);
    }, [JSON.stringify(props.tokenNetworks)]);

    useEffect(function updateAppIdNetworks() {
        setAppIdNetworks(props.appIdNetworks);
    }, [JSON.stringify(props.appIdNetworks)]);

    useEffect(function updateRemainingMvpds() {
        const updatedRemainingMvpds = [];
        const mvpdIds = [];
        for (const mvpd of selectedMvpds) {
            mvpdIds.push(mvpd.toLowerCase());
        }

        for (const mvpd of Object.keys(mvpds)) {
            const lowercaseMvpd = mvpd.toLowerCase();
            if (!mvpdIds.includes(lowercaseMvpd)) {
                updatedRemainingMvpds.push({key: lowercaseMvpd, text: lowercaseMvpd, value: lowercaseMvpd});
            }
        }
        setRemainingMvpdOptions(updatedRemainingMvpds);
    }, [JSON.stringify(selectedMvpds), JSON.stringify(mvpds)]);

    useEffect(function updateRemainingTokenNetworks() {
        const updatedRemainingTokenNetworks = [];
        const networkIds = [];
        for (const network of selectedTokenNetworks) {
            networkIds.push(network.id);
        }

        for (const network of Object.keys(tokenNetworks)) {
            if (!networkIds.includes(network)) {
                updatedRemainingTokenNetworks.push({key: network, text: tokenNetworks[network], value: network});
            }
        }
        setRemainingTokenNetworkOptions(updatedRemainingTokenNetworks);
    }, [JSON.stringify(selectedTokenNetworks), JSON.stringify(tokenNetworks)]);

    useEffect(function updateRemainingAssetNetworks() {
        const updatedRemainingAssetNetworks = [];
        const networkIds = [];
        for (const network of selectedAssetNetworks) {
            networkIds.push(network.id);
        }

        for (const network of Object.keys(assetNetworks)) {
            if (!networkIds.includes(network)) {
                updatedRemainingAssetNetworks.push({key: network, text: assetNetworks[network], value: network});
            }
        }
        setRemainingAssetNetworks(updatedRemainingAssetNetworks);
    }, [JSON.stringify(selectedAssetNetworks), JSON.stringify(assetNetworks)]);

    useEffect(function updateRemainingAppIdNetworks() {
        const updatedRemainingAppIdNetworks = [];
        const networkIds = [];
        for (const network of selectedAppIdNetworks) {
            networkIds.push(network.id);
        }

        for (const network of appIdNetworks) {
            if (!networkIds.includes(network)) {
                updatedRemainingAppIdNetworks.push({key: network, text: network, value: network});
            }
        }
        setRemainingAppIdNetworks(updatedRemainingAppIdNetworks);
    }, [JSON.stringify(selectedAppIdNetworks), JSON.stringify(appIdNetworks)]);

    const onClickSave = () => {
        const country = selectedCountries.map(country => country.id);
        const network = selectedTokenNetworks.map(network => network.id);
        const mvpd = selectedMvpds.map(mvpd => mvpd.toLowerCase());
        const geolocationSetting = {
            name,
            description,
            index: initialGeofencingSettings.index,
            ...allowed === false && {allowed},
            ...selectedCountries.length > 0 && {country},
            ...selectedTokenNetworks.length > 0 && {tokennetwork: network},
            ...selectedMvpds.length > 0 && {mvpd},
            ...selectedTokenProducts.length > 0 && {tokenproduct: selectedTokenProducts},
            ...selectedAssetProducts.length > 0 && {assetproduct: selectedAssetProducts},
            ...requestors.length > 0 && {requestor: requestors},
            ...medias.length > 0 && {media: medias},
            ...paths.length > 0 && {path: paths},
            ...selectedAppIdNetworks.length > 0 && {appidnetwork: selectedAppIdNetworks},
            ...ips.length > 0 && {ip: ips},
            ...selectedAssetNetworks.length > 0 && {assetnetwork: selectedAssetNetworks},
            ...assetEntitlements.length > 0 && {assetentitlement: assetEntitlements},
            ...tokenEntitlements.length > 0 && {tokenentitlement: tokenEntitlements},
            ...start !== null && {start: Math.floor(start.getTime() / 1000)},
            ...end !== null && {end: Math.floor(end.getTime() / 1000)}
        }
        console.log("about to submit: ", geolocationSetting);
        props.onClickSave(geolocationSetting);
    };

    const onClickDelete = () => {
        props.onClickDelete(props.selectedGeofence.index);
        setDeletionModalOpen(false);
    };

    return (
        <Container>
            <Modal
                onClose={() => setDeletionModalOpen(false)}
                open={deletionModalOpen}
                size="small"
                >
                <Modal.Header>Delete Selected Geolocation Rule</Modal.Header>
                <Modal.Content>
                    Are you sure you want to delete the selected geolocation rule?
                </Modal.Content>
                <Modal.Actions>
                    <Button primary icon onClick={() => onClickDelete()}><Icon name="trash alternate outline"/> Delete</Button>
                    <Button onClick={() => setDeletionModalOpen(false)}>Cancel</Button>
                </Modal.Actions>
            </Modal>
            {
                props.saving ?
                    <Message icon color="blue">
                        <Icon name="spinner" loading />
                        <Message.Content>Saving geolocation settings...</Message.Content>
                    </Message> :
                props.saveErrorMessage ?
                    <Message icon color="red">
                        <Icon name="exclamation" />
                        <Message.Content>{props.saveErrorMessage}</Message.Content>
                    </Message> :
                props.saveSuccessMessage ?
                    <Message icon color="green">
                        <Icon name="save outline" />
                        <Message.Content>{props.saveSuccessMessage}</Message.Content>
                    </Message> : ""
            }
            {
                !props.saving && !props.loadingMvpds && !props.loadingTokenNetworks && !props.loadingAppIdNetworks ?
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Popup
                                    open={props.userCanEdit && props.unsavedChanges && (props.newClicked || Object.keys(props.selectedGeofence).length > 0 ? name.length > 0 && !props.allNames.includes(name) : true)}
                                    content="You have made unsaved changes."
                                    trigger={
                                        <ContingentButton
                                            icon
                                            color="green"
                                            onClick={() => onClickSave()}
                                            disabled={props.newClicked || Object.keys(props.selectedGeofence).length > 0 ? name.length < 1 || props.allNames.includes(name) || !props.unsavedChanges : !props.unsavedChanges}
                                            service={props.service}
                                            module={props.module}
                                            scope="all"
                                            allPermissions={props.permissions}
                                            user={props.user}
                                        ><Icon name="save outline"/> Save Geolocation Settings</ContingentButton>
                                    }
                                    position="right center"
                                />
                            </Form.Field>
                            <Form.Field>
                                {
                                    Object.keys(props.selectedGeofence).length > 0 && props.selectedGeofence.index !== undefined ?
                                        <ContingentButton
                                            icon
                                            color="red"
                                            onClick={() => setDeletionModalOpen(true)}
                                            floated="right"
                                            service={props.service}
                                            module={props.module}
                                            scope="all"
                                            allPermissions={props.permissions}
                                            user={props.user}
                                        ><Icon name="trash alternate outline"/> Delete Geolocation Setting</ContingentButton> : ""
                                }
                            </Form.Field>
                        </Form.Group>
                    </Form> : ""
            }
            {
                props.loadingMvpds ?
                    <Message icon color="yellow">
                        <Icon name="spinner" loading />
                        <Message.Content>Loading MVPDs...</Message.Content>
                    </Message> :
                props.loadingMvpdsErrorMessage ?
                    <Message icon color="red">
                        <Icon name="exclamation" />
                        <Message.Content>{props.loadingMvpdsErrorMessage}</Message.Content>
                    </Message> :
                props.loadingTokenNetworks ?
                    <Message icon color="yellow">
                        <Icon name="spinner" loading />
                        <Message.Content>Loading Token Networks...</Message.Content>
                    </Message> :
                props.loadingTokenNetworksErrorMessage ?
                    <Message icon color="red">
                        <Icon name="exclamation" />
                        <Message.Content>{props.loadingTokenNetworksErrorMessage}</Message.Content>
                    </Message> :
                Object.keys(props.selectedGeofence).length < 1 && !props.newClicked ?
                    <Message icon color="yellow">
                        <Icon name="info" />
                        <Message.Content>Select a geolocation setting or click the "Create New" button to continue.</Message.Content>
                    </Message> :
                    <Form>
                        <Form.Group widths="equal">
                            <Popup
                                trigger={
                                    <Form.Field
                                        label="Name"
                                        control={Input}
                                        value={name}
                                        onChange={(event, {value}) => setName(value)}
                                    />
                                }
                                open={name.length < 1 || props.allNames.includes(name)}
                                content="Geolocation settings require a unique name."
                            />
                            <Form.Field width={2}>
                                <label>&nbsp;</label>
                                <Checkbox
                                    label="Allowed"
                                    control={Checkbox}
                                    toggle
                                    checked={allowed !== false}
                                    onClick={(event, {checked}) => setAllowed(checked)}
                                    readOnly={!props.userCanEdit}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field
                                label="Description"
                                control={TextArea}
                                value={description}
                                onChange={(event, {value}) => setDescription(value)}
                                readOnly={!props.userCanEdit}
                            />
                        </Form.Group>
                        <Segment basic style={{background: selectedCountries.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>Countries</Divider>
                            {
                                props.userCanEdit &&
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label="Select Countries to Add"
                                            control={Dropdown}
                                            options={remainingCountryOptions}
                                            value={countriesToAdd}
                                            fluid
                                            multiple
                                            clearable
                                            search
                                            selection
                                            allowAdditions
                                            onChange={(event, {value}) => setCountriesToAdd(value)}
                                            onAddItem={(event, {value}) => setRemainingCountryOptions(remainingCountryOptions.concat([{
                                                key: value,
                                                text: value,
                                                value,
                                                flag: value
                                            }]))}
                                        />
                                        <Form.Field
                                            label="&nbsp;"
                                            control={Button}
                                            onClick={() => {
                                                const formattedCountries = countriesToAdd.map(country => {
                                                    return {id: country, displayName: CountryCodes[country]};
                                                });
                                                const updatedCountries = formattedCountries.concat(selectedCountries);
                                                setSelectedCountries(updatedCountries);
                                                setCountriesToAdd([]);
                                            }}
                                            content="Add Selected Countries"
                                            fluid
                                            disabled={!props.userCanEdit}
                                        />
                                    </Form.Group>
                            }
                            <Label.Group>
                                {
                                    selectedCountries.map((country, index) =>
                                        <Label
                                            as="a"
                                            circular
                                            key={`country-${country.id}`}
                                            onClick={() => {
                                                let updatedCountries = selectedCountries.slice();
                                                updatedCountries.splice(index, 1);
                                                setSelectedCountries(updatedCountries);
                                            }}
                                        >
                                            <Flag name={country.id} />
                                            <pre>[{country.id}]</pre> {country.displayName}
                                            <Icon name="delete" />
                                        </Label>
                                    )
                                }
                            </Label.Group>
                        </Segment>
                        <Segment basic style={{background: ips.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>IP Ranges</Divider>
                            {
                                props.userCanEdit &&
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label="Enter IP CIDR to Add"
                                            control={Input}
                                            fluid
                                            value={ipToAdd}
                                            error={ipToAdd.length > 0 && !(CIDR_REGEX.test(ipToAdd) || IP_REGEX.test(ipToAdd))}
                                            onChange={(event, {value}) => setIpToAdd(value)}
                                        />
                                        <Form.Field
                                            label="&nbsp;"
                                            control={Button}
                                            onClick={() => {
                                                const updatedIps = ips.concat(ipToAdd);
                                                setIps(updatedIps);
                                                setIpToAdd("");
                                            }}
                                            content="Add Selected IP"
                                            disabled={!props.userCanEdit || (ipToAdd.length > 0 && !(CIDR_REGEX.test(ipToAdd) || IP_REGEX.test(ipToAdd)))}
                                            fluid
                                        />
                                    </Form.Group>
                            }
                            {
                                ips.map((ip, index) => {
                                    return <Label
                                        as="a"
                                        circular
                                        key={`ip-${ip}`}
                                        onClick={() => {
                                            let updatedIps = ips.slice();
                                            updatedIps.splice(index, 1);
                                            setIps(updatedIps);
                                        }}
                                    >
                                        {ip}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <Segment basic style={{background: medias.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>Media</Divider>
                            {
                                props.userCanEdit &&
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label="Enter Media to Add"
                                            control={Input}
                                            fluid
                                            value={mediaToAdd}
                                            onChange={(event, {value}) => setMediaToAdd(value)}
                                        />
                                        <Form.Field
                                            label="&nbsp;"
                                            control={Button}
                                            onClick={() => {
                                                const updatedMedia = medias.concat(mediaToAdd);
                                                setMedias(updatedMedia);
                                                setMediaToAdd("");
                                            }}
                                            content="Add Selected Media"
                                            fluid
                                            disabled={!props.userCanEdit}
                                        />
                                    </Form.Group>
                            }
                            {
                                medias.map((media, index) => {
                                    return <Label
                                        as="a"
                                        circular
                                        key={`media-${media}`}
                                        onClick={() => {
                                            let updatedMedia = medias.slice();
                                            updatedMedia.splice(index, 1);
                                            setMedias(updatedMedia);
                                        }}
                                    >
                                        {media}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <Segment basic style={{background: paths.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>Paths</Divider>
                            {
                                props.userCanEdit &&
                                <Form.Group widths="equal">
                                    <Form.Field
                                        label="Enter Path to Add"
                                        control={Input}
                                        fluid
                                        value={pathToAdd}
                                        onChange={(event, {value}) => setPathToAdd(value)}
                                    />
                                    <Form.Field
                                        label="&nbsp;"
                                        control={Button}
                                        onClick={() => {
                                            const updatedPaths = paths.concat(pathToAdd);
                                            setPaths(updatedPaths);
                                            setPathToAdd("");
                                        }}
                                        content="Add Selected Path"
                                        fluid
                                        disabled={!props.userCanEdit}
                                    />
                                </Form.Group>
                            }
                            {
                                paths.map((path, index) => {
                                    return <Label
                                        as="a"
                                        circular
                                        key={`path-${path}`}
                                        onClick={() => {
                                            let updatedPaths = paths.slice();
                                            updatedPaths.splice(index, 1);
                                            setPaths(updatedPaths);
                                        }}
                                    >
                                        {path}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <Segment basic style={{background: selectedMvpds.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>MVPDs</Divider>
                            {
                                props.userCanEdit &&
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label="Select MVPDs to Add"
                                            control={Dropdown}
                                            options={remainingMvpdOptions}
                                            value={mvpdsToAdd}
                                            fluid
                                            multiple
                                            clearable
                                            search
                                            selection
                                            allowAdditions
                                            onChange={(event, {value}) => setMvpdsToAdd(value)}
                                            onAddItem={(event, {value}) => setRemainingMvpdOptions(remainingMvpdOptions.concat([{
                                                key: value,
                                                text: value,
                                                value
                                            }]))}
                                        />
                                        <Form.Field
                                            label="&nbsp;"
                                            control={Button}
                                            onClick={() => {
                                                const updatedMvpds = mvpdsToAdd.concat(selectedMvpds);
                                                setSelectedMvpds(updatedMvpds);
                                                setMvpdsToAdd([]);
                                            }}
                                            content="Add Selected MVPDs"
                                            fluid
                                            disabled={!props.userCanEdit}
                                        />
                                    </Form.Group>
                            }
                            {
                                selectedMvpds.map((mvpd, index) => {
                                    return <Label
                                        as="a"
                                        key={`mvpd-${mvpd}`}
                                        circular
                                        onClick={() => {
                                            let updatedMvpds = selectedMvpds.slice();
                                            updatedMvpds.splice(index, 1);
                                            setSelectedMvpds(updatedMvpds);
                                        }}
                                    >
                                        {mvpd}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <Segment basic style={{background: requestors.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>Requestors</Divider>
                            {
                                props.userCanEdit &&
                                    <Form.Group widths="equal">
                                    <Form.Field
                                        label="Enter Requestor to Add"
                                        control={Input}
                                        fluid
                                        value={requestorToAdd}
                                        onChange={(event, {value}) => setRequestorToAdd(value)}
                                    />
                                    <Form.Field
                                        label="&nbsp;"
                                        control={Button}
                                        onClick={() => {
                                            const updatedRequestors = requestors.concat(requestorToAdd);
                                            setRequestors(updatedRequestors);
                                            setRequestorToAdd("");
                                        }}
                                        content="Add Selected Requestor"
                                        fluid
                                        disabled={!props.userCanEdit}
                                    />
                                </Form.Group>
                            }
                            {
                                requestors.map((requestor, index) => {
                                    return <Label
                                        as="a"
                                        circular
                                        key={`requestor-${requestor}`}
                                        onClick={() => {
                                            let updatedRequestors = requestors.slice();
                                            updatedRequestors.splice(index, 1);
                                            setRequestors(updatedRequestors);
                                        }}
                                    >
                                        {requestor}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <Segment basic style={{background: selectedAppIdNetworks.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>App ID Networks</Divider>
                            {
                                props.userCanEdit &&
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label="Enter App ID Network to Add"
                                            control={Dropdown}
                                            options={remainingAppIdNetworks}
                                            search
                                            selection
                                            multiple
                                            clearable
                                            allowAdditions
                                            fluid
                                            value={appIdNetworksToAdd}
                                            onChange={(event, {value}) => setAppIdNetworksToAdd(value)}
                                            onAddItem={(event, {value}) => setRemainingAppIdNetworks(remainingAppIdNetworks.concat[{
                                                key: value,
                                                text: value,
                                                value
                                            }])}
                                        />
                                        <Form.Field
                                            label="&nbsp;"
                                            control={Button}
                                            onClick={() => {
                                                const updatedAppIdNetwork = selectedAppIdNetworks.concat(appIdNetworksToAdd);
                                                setSelectedAppIdNetworks(updatedAppIdNetwork);
                                                setAppIdNetworksToAdd([]);
                                            }}
                                            content="Add Selected App ID Network"
                                            fluid
                                            disabled={!props.userCanEdit}
                                        />
                                    </Form.Group>
                            }
                            {
                                selectedAppIdNetworks.map((appIdNetwork, index) => {
                                    return <Label
                                        as="a"
                                        circular
                                        key={`appidnetwork-${appIdNetwork}`}
                                        onClick={() => {
                                            let updatedAppIdNetwork = selectedAppIdNetworks.slice();
                                            updatedAppIdNetwork.splice(index, 1);
                                            setSelectedAppIdNetworks(updatedAppIdNetwork);
                                        }}
                                    >
                                        {appIdNetwork}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <Segment basic style={{background: selectedAssetNetworks.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>Asset Networks</Divider>
                            {
                                props.userCanEdit &&
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label="Enter Asset Network to Add"
                                            control={Dropdown}
                                            options={remainingAssetNetworks}
                                            fluid
                                            multiple
                                            clearable
                                            search
                                            selection
                                            allowAdditions
                                            value={assetNetworksToAdd}
                                            onChange={(event, {value}) => setAssetNetworksToAdd(value)}
                                            onAddItem={(event, {value}) => setRemainingAssetNetworks(remainingAssetNetworks.concat({
                                                key: value,
                                                text: value,
                                                value
                                            }))}
                                        />
                                        <Form.Field
                                            label="&nbsp;"
                                            control={Button}
                                            onClick={() => {
                                                const updatedAssetNetworks = selectedAssetNetworks.concat(assetNetworksToAdd);
                                                setSelectedAssetNetworks(updatedAssetNetworks);
                                                setAssetNetworksToAdd([]);
                                            }}
                                            content="Add Selected Asset Network"
                                            fluid
                                            disabled={!props.userCanEdit}
                                        />
                                    </Form.Group>
                            }
                            {
                                selectedAssetNetworks.map((assetNetwork, index) => {
                                    return <Label
                                        as="a"
                                        circular
                                        key={`assetnetwork-${assetNetwork}`}
                                        onClick={() => {
                                            let updatedAssetNetworks = selectedAssetNetworks.slice();
                                            updatedAssetNetworks.splice(index, 1);
                                            setSelectedAssetNetworks(updatedAssetNetworks);
                                        }}
                                    >
                                        {assetNetwork}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <Segment basic style={{background: selectedTokenNetworks.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>Token Networks</Divider>
                            {
                                props.userCanEdit &&
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label="Select Token Networks to Add"
                                            control={Dropdown}
                                            options={remainingTokenNetworkOptions}
                                            fluid
                                            multiple
                                            clearable
                                            search
                                            selection
                                            allowAdditions
                                            value={tokenNetworksToAdd}
                                            onChange={(event, {value}) => setTokenNetworksToAdd(value)}
                                            onAddItem={(event, {value}) => setRemainingTokenNetworkOptions(remainingTokenNetworkOptions.concat([{
                                                key: value,
                                                text: value,
                                                value
                                            }]))}
                                        />
                                        <Form.Field
                                            label="&nbsp;"
                                            control={Button}
                                            onClick={() => {
                                                const formattedNetworks = tokenNetworksToAdd.map(network => {
                                                    return {id: network, displayName: tokenNetworks[network]};
                                                })
                                                const updatedNetworks = formattedNetworks.concat(selectedTokenNetworks);
                                                setSelectedTokenNetworks(updatedNetworks)
                                                setTokenNetworksToAdd([]);
                                            }}
                                            content="Add Selected Token Networks"
                                            fluid
                                            disabled={!props.userCanEdit}
                                        />
                                    </Form.Group>
                            }
                            {
                                selectedTokenNetworks.map((network, index) => {
                                    return <Label
                                        as="a"
                                        circular
                                        key={`token-network-${network.id}`}
                                        onClick={() => {
                                            let updatedNetworks = selectedTokenNetworks.slice();
                                            updatedNetworks.splice(index, 1);
                                            setSelectedTokenNetworks(updatedNetworks);
                                        }}
                                    >
                                        {network.displayName ? network.displayName : network.id ? network.id : JSON.stringify(network)}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <Segment basic style={{background: assetEntitlements.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>Asset Entitlements</Divider>
                            {
                                props.userCanEdit &&
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label="Enter Asset Entitlement to Add"
                                            control={Input}
                                            fluid
                                            value={assetEntitlementToAdd}
                                            onChange={(event, {value}) => setAssetEntitlementToAdd(value)}
                                        />
                                        <Form.Field
                                            label="&nbsp;"
                                            control={Button}
                                            onClick={() => {
                                                const updatedAssetEntitlements = assetEntitlements.concat(assetEntitlementToAdd.toLowerCase().replaceAll(/[ /_-]/g, ""));
                                                setAssetEntitlements(updatedAssetEntitlements);
                                                setAssetEntitlementToAdd("");
                                            }}
                                            content="Add Selected Asset Entitlement"
                                            fluid
                                            disabled={!props.userCanEdit}
                                        />
                                    </Form.Group>
                            }
                            {
                                assetEntitlements.map((assetEntitlement, index) => {
                                    return <Label
                                        as="a"
                                        circular
                                        key={`assetentitlement-${assetEntitlement}`}
                                        onClick={() => {
                                            let updatedAssetEntitlements = assetEntitlements.slice();
                                            updatedAssetEntitlements.splice(index, 1);
                                            setAssetEntitlements(updatedAssetEntitlements);
                                        }}
                                    >
                                        {assetEntitlement}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <Segment basic style={{background: tokenEntitlements.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>Token Entitlements</Divider>
                            {
                                props.userCanEdit &&
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label="Enter Token Entitlement to Add"
                                            control={Input}
                                            fluid
                                            value={tokenEntitlementToAdd}
                                            onChange={(event, {value}) => setTokenEntitlementToAdd(value)}
                                        />
                                        <Form.Field
                                            label="&nbsp;"
                                            control={Button}
                                            onClick={() => {
                                                const updatedTokenEntitlements = tokenEntitlements.concat(tokenEntitlementToAdd.toLowerCase().replaceAll(/[ /_-]/g, ""));
                                                setTokenEntitlements(updatedTokenEntitlements);
                                                setTokenEntitlementToAdd("");
                                            }}
                                            content="Add Selected Token Entitlement"
                                            fluid
                                            disabled={!props.userCanEdit}
                                        />
                                    </Form.Group>
                            }
                            {
                                tokenEntitlements.map((tokenEntitlement, index) => {
                                    return <Label
                                        as="a"
                                        circular
                                        key={`tokenentitlement-${tokenEntitlement}`}
                                        onClick={() => {
                                            let updatedTokenEntitlements = tokenEntitlements.slice();
                                            updatedTokenEntitlements.splice(index, 1);
                                            setTokenEntitlements(updatedTokenEntitlements);
                                        }}
                                    >
                                        {tokenEntitlement}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <Segment basic style={{background: selectedAssetProducts.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>Asset Products</Divider>
                            {
                                props.userCanEdit &&
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label="Enter Asset Product to Add"
                                            control={Input}
                                            fluid
                                            value={assetProductToAdd}
                                            onChange={(event, {value}) => setAssetProductToAdd(value)}
                                        />
                                        <Form.Field
                                            label="&nbsp;"
                                            control={Button}
                                            onClick={() => {
                                                const updatedAssetProducts = selectedAssetProducts.concat(assetProductToAdd.toLowerCase().replaceAll(/[ /_-]/g, ""));
                                                setSelectedAssetProducts(updatedAssetProducts);
                                                setAssetProductToAdd("");
                                            }}
                                            content="Add Selected Asset Product"
                                            fluid
                                            disabled={!props.userCanEdit}
                                        />
                                    </Form.Group>
                            }
                            {
                                selectedAssetProducts.map((product, index) => {
                                    return <Label
                                        as="a"
                                        circular
                                        key={`asset-product-${product}`}
                                        onClick={() => {
                                            let updatedAssetProducts = selectedAssetProducts.slice();
                                            updatedAssetProducts.splice(index, 1);
                                            setSelectedAssetProducts(updatedAssetProducts);
                                        }}
                                    >
                                        {product}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <Segment basic style={{background: selectedTokenProducts.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>Token Products</Divider>
                            {
                                props.userCanEdit &&
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label="Enter Token Product to Add"
                                            control={Input}
                                            fluid
                                            value={tokenProductToAdd}
                                            onChange={(event, {value}) => setTokenProductToAdd(value)}
                                        />
                                        <Form.Field
                                            label="&nbsp;"
                                            control={Button}
                                            onClick={() => {
                                                const updatedTokenProducts = selectedTokenProducts.concat(tokenProductToAdd.toLowerCase().replaceAll(/[ /_-]/g, ""));
                                                setSelectedTokenProducts(updatedTokenProducts);
                                                setTokenProductToAdd("");
                                            }}
                                            content="Add Selected Token Product"
                                            fluid
                                            disabled={!props.userCanEdit}
                                        />
                                    </Form.Group>
                            }
                            {
                                selectedTokenProducts.map((product, index) => {
                                    return <Label
                                        as="a"
                                        circular
                                        key={`token-product-${product}`}
                                        onClick={() => {
                                            let updatedTokenProducts = selectedTokenProducts.slice();
                                            updatedTokenProducts.splice(index, 1);
                                            setSelectedTokenProducts(updatedTokenProducts);
                                        }}
                                    >
                                        {product}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <Segment basic style={{background: start ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>Start</Divider>
                            {
                                props.userCanEdit &&
                                    <Form.Group widths="equal">
                                        <Form.Field>
                                            <label>&nbsp;</label>
                                            <MuiPickersUtilsProvider fluid utils={DateFnsUtils}>
                                                <DateTimePicker
                                                    inputVariant="outlined"
                                                    fullWidth
                                                    value={start}
                                                    onChange={setStart}
                                                    clearable
                                                    format="yyyy/MM/dd hh:mm:ss a"
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>&nbsp;</label>
                                            <Button fluid onClick={() => setStart(null)}
                                                    disabled={start === null || !props.userCanEdit}>Clear Start</Button>
                                        </Form.Field>
                                    </Form.Group>
                            }
                        </Segment>
                        <Segment basic style={{background: end ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>End</Divider>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <MuiPickersUtilsProvider fluid utils={DateFnsUtils}>
                                        <DateTimePicker
                                            inputVariant="outlined"
                                            fullWidth
                                            value={end}
                                            onChange={setEnd}
                                            clearable
                                            format="yyyy/MM/dd hh:mm:ss a"
                                        />
                                    </MuiPickersUtilsProvider>
                                </Form.Field>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Button fluid onClick={() => setEnd(null)} disabled={end === null || !props.userCanEdit}>Clear End</Button>
                                </Form.Field>
                            </Form.Group>
                        </Segment>
                    </Form>
            }
        </Container>
    );
};

TokenGeofencingDetailView.propTypes = {
    selectedGeofence: PropTypes.object.isRequired,
    setIsSelectedGeolocationSettingUpdated: PropTypes.func.isRequired,
    mvpds: PropTypes.array.isRequired,
    tokenNetworks: PropTypes.array.isRequired,
    appIdNetworks: PropTypes.array.isRequired,
    onClickSave: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    saveErrorMessage: PropTypes.string.isRequired,
    saveSuccessMessage: PropTypes.string.isRequired,
    loadingMvpds: PropTypes.bool.isRequired,
    loadingTokenNetworks: PropTypes.bool.isRequired,
    loadingAppIdNetworks: PropTypes.bool.isRequired,
    allNames: PropTypes.array.isRequired,
    loadingMvpdsErrorMessage: PropTypes.string.isRequired,
    loadingTokenNetworksErrorMessage: PropTypes.string.isRequired,
    loadingAppIdNetworksErrorMessage: PropTypes.string.isRequired,
    unsavedChanges: PropTypes.bool.isRequired,
    newClicked: PropTypes.bool.isRequired,
    userCanEdit: PropTypes.bool.isRequired,
    service: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    permissions: PropTypes.array.isRequired,
    userPermissions: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired
};
