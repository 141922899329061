import React, {useState, useEffect} from "react";
import {Grid} from "semantic-ui-react";
import TokenGeofencingSelector from "./TokenGeofencingSelector";
import TokenGeofencingDetailView from "./TokenGeofencingDetailView";

export default function TokenGeofencingArrangementTab(props) {
    const [geolocationData, setGeolocationData] = useState([]);
    const [allNames, setAllNames] = useState([]);
    const [selectedGeofence, setSelectedGeofence] = useState({});
    const [loadingGeolocationData, setLoadingGeolocationData] = useState(false);
    const [loadingMvpds, setLoadingMvpds] = useState(false);
    const [loadingTokenNetworks, setLoadingTokenNetworks] = useState(false);
    const [loadingAppIdNetworks, setLoadingAppIdNetworks] = useState(false);
    const [loadingMvpdsErrorMessage, setLoadingMvpdsErrorMessage] = useState("");
    const [loadingTokenNetworksErrorMessage, setLoadingTokenNetworksErrorMessage] = useState("");
    const [loadingAppIdNetworksErrorMessage, setLoadingAppIdNetworksErrorMessage] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveErrorMessage, setSaveErrorMessage] = useState("");
    const [saveSuccessMessage, setSaveSuccessMessage] = useState("");
    const [tokenNetworks, setTokenNetworks] = useState({});
    const [appIdNetworks, setAppIdNetworks] = useState([]);
    const [mvpds, setMvpds] = useState({});
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [isSelectedGeolocationSettingUpdated, setIsSelectedGeolocationSettingUpdated] = useState(false);
    const [isGeolocationSettingsRearranged, setIsGeolocationSettingsRearranged] = useState(false);
    const [rulesSelected, setRulesSelected] = useState({});
    const [openExportModal, setOpenExportModal] = useState(false);
    const [newClicked, setNewClicked] = useState(false);

    useEffect(function initializeGeolocationData() {
        props.refreshGeolocationData();
    }, []);

    useEffect(function initializeTVEManagerData() {
        refreshTokenNetworks();
        refreshMvpds();
    }, []);

    useEffect(function initializeAppIdNetworks() {
        refreshAppIdNetworks();
    }, []);

    useEffect(function initializeTokenNetworks() {
        refreshTokenNetworks();
    }, [JSON.stringify(props.brandMapping)]);

    useEffect(function updateGeolocationData() {
        setGeolocationData(props.geolocationData);
    }, [JSON.stringify(props.geolocationData)]);

    useEffect(function updateUnsavedChanges() {
        setUnsavedChanges(isSelectedGeolocationSettingUpdated || isGeolocationSettingsRearranged);
    }, [isGeolocationSettingsRearranged, isSelectedGeolocationSettingUpdated]);

    useEffect(function onReorderingSettings() {
        let areSettingsReordered = false;
        for (let i = 0; i < geolocationData.length; i++) {
            if (i !== geolocationData[i].index) {
                areSettingsReordered = true;
                break;
            }
        }
        setIsGeolocationSettingsRearranged(areSettingsReordered);
    }, [JSON.stringify(geolocationData)]);

    useEffect(function onNewClicked() {
        if (newClicked) {
            setGeolocationData(geolocationData.slice().concat({}));
            setSelectedGeofence({});
            setSaveSuccessMessage("");
        } else {
            const savedGeolocationSettings = [];
            for (const geolocationSetting of geolocationData) {
                if (Object.keys(geolocationSetting).length > 0) {
                    savedGeolocationSettings.push(geolocationSetting);
                }
            }
            setGeolocationData(savedGeolocationSettings);
        }
    }, [newClicked]);

    useEffect(function updateAllNames() {
        const updatedAllNames = [];
        for (const setting of geolocationData) {
            if (setting.name && selectedGeofence.name !== setting.name) {
                updatedAllNames.push(setting.name);
            }
        }
        setAllNames(updatedAllNames);
    }, [JSON.stringify(geolocationData), JSON.stringify(selectedGeofence)]);

    useEffect(function onSelectNewGeofence() {
        setSaveSuccessMessage("");
        setSaveErrorMessage("");
        if (Object.keys(selectedGeofence).length > 0) {
            setNewClicked(false);
        }
    }, [JSON.stringify(selectedGeofence)]);

    const refreshAppIdNetworks = () => {
        setLoadingAppIdNetworks(true);
        setLoadingAppIdNetworksErrorMessage("");
        props.ARADataProvider.getAll().then(appIds => {
            const networks = new Set();
            for (const appId of appIds) {
                const network = props.brandMapping[appId.network] ? props.brandMapping[appId.network] : appId.network;
                networks.add(network);
            }
            setAppIdNetworks(Array.from(networks));
            return networks;
        }).catch(error => {
            console.error(error);
            setAppIdNetworks([]);
            setLoadingAppIdNetworksErrorMessage("There was an error loading app id networks.");

        }).finally(() => {
            setLoadingAppIdNetworks(false);
        });
    };

    const refreshTokenNetworks = () => {
        setLoadingTokenNetworks(true);
        setLoadingTokenNetworksErrorMessage("");
        props.OriginCDNMapperDataProvider.getAll(props.user).then(response => {
            const updatedTokenNetworks = {};
            for (const map of response) {
                let brand = map.brand.replaceAll(/[ ]/g, "").toLowerCase();
                if (props.brandMapping.hasOwnProperty(brand)) {
                    brand = props.brandMapping[brand];
                }
                if (!updatedTokenNetworks.hasOwnProperty(brand)) {
                    updatedTokenNetworks[brand] = `[${brand}] ${map.brand}`;
                }
            }
            setTokenNetworks(updatedTokenNetworks);
        }).catch(error => {
            console.error(error);
            setLoadingTokenNetworksErrorMessage("There was an error loading token networks from the Origin-CDN Mapper.");
        }).finally(() => {
            setLoadingTokenNetworks(false);
        });
    };

    const refreshMvpds = () => {
        setLoadingMvpds(true);
        setLoadingMvpdsErrorMessage("");
        props.TVEManagerDataProvider.getProviders().then(response => {
            if (response.hasOwnProperty("error")) {
                setLoadingMvpdsErrorMessage("There was an error loading the MVPDs.");
            }
            const updatedMvpds = {};
            if (response.hasOwnProperty("providers")) {
                for (const mvpd of response.providers) {
                    updatedMvpds[mvpd.id] = mvpd.displayName;
                }
            }
            setMvpds(updatedMvpds);
        }).catch(error => {
            console.error(error);
            setMvpds([]);
            setLoadingMvpdsErrorMessage("There was an error loading the MVPDs.");
        }).finally(() => {
            setLoadingMvpds(false);
        });
    };

    const onClickRefresh = () => {
        setSaveSuccessMessage("");
        setSaveErrorMessage("");
        props.refreshGeolocationData();
        refreshMvpds();
        refreshTokenNetworks();
    };

    const onClickDelete = index => {
        const geolocationDataToSave = geolocationData.slice();
        geolocationDataToSave.splice(index, 1);
        for (const geofence of geolocationDataToSave) {
            delete geofence.index;
        }
        const metadata = {"geolocation_settings_v2": geolocationDataToSave};
        setSaving(true);
        setSaveErrorMessage("");
        setSaveSuccessMessage("");
        console.log("deletion: ", geolocationDataToSave);
        props.TokenMetadataDataProvider.setMetadata(metadata).then(response => {
            response.json().then(json => {
                setSaveSuccessMessage("The selected geolocation settings were deleted successfully.");
                setSelectedGeofence({});
            })
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There was an error deleting the selected geolocation settings.");
        }).finally(() => {
            props.refreshGeolocationData();
            setSaving(false);
        });
    };

    const onClickSave = updatedGeofence => {
        const geolocationDataToSave = geolocationData.slice();
        for (let i = 0; i < geolocationDataToSave.length; i++) {
            if (geolocationDataToSave[i].index === updatedGeofence.index) {
                geolocationDataToSave[i] = Object.assign({}, updatedGeofence);
                break;
            }
        }
        for (const geofence of geolocationDataToSave) {
            delete geofence.index;
        }
        const metadata = {"geolocation_settings_v2": geolocationDataToSave};
        setSaving(true);
        setSaveErrorMessage("");
        setSaveSuccessMessage("");
        props.TokenMetadataDataProvider.setMetadata(metadata).then(response => {
            response.json().then(json => {
                setSaveSuccessMessage("Geolocation settings were successfully saved.");
                setNewClicked(false);
                setSelectedGeofence(updatedGeofence);
            });
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There was an error saving geolocation settings.");
        }).finally(() => {
            props.refreshGeolocationData();
            setSaving(false);
        });
    };

    return (
        <Grid className="masterContainer">
            <Grid.Column width={5}>
                <TokenGeofencingSelector
                    geolocationData={geolocationData}
                    selectedGeofence={selectedGeofence}
                    setSelectedGeofence={setSelectedGeofence}
                    loadingGeolocationData={loadingGeolocationData}
                    setGeolocationData={setGeolocationData}
                    refreshGeolocationData={onClickRefresh}
                    toast={props.toast}
                    setNewClicked={setNewClicked}
                    rulesSelected={rulesSelected}
                    setRulesSelected={setRulesSelected}
                    openExportModal={openExportModal}
                    setOpenExportModal={setOpenExportModal}
                    userCanEdit={props.userCanEdit}
                    service={props.service}
                    module={props.module}
                    permissions={props.permissions}
                    userPermissions={props.userPermissions}
                    user={props.user}
                />
            </Grid.Column>
            <Grid.Column width={11} style={{maxHeight: "72vh", overflowY: "auto", overflowX: "hidden"}}>
                <TokenGeofencingDetailView
                    mvpds={mvpds}
                    allNames={allNames}
                    tokenNetworks={tokenNetworks}
                    selectedGeofence={selectedGeofence}
                    loadingMvpds={loadingMvpds}
                    loadingTokenNetworks={loadingTokenNetworks}
                    loadingMvpdsErrorMessage={loadingMvpdsErrorMessage}
                    loadingTokenNetworksErrorMessage={loadingTokenNetworksErrorMessage}
                    appIdNetworks={appIdNetworks}
                    loadingAppIdNetworks={loadingAppIdNetworks}
                    loadingAppIdNetworksErrorMessage={loadingAppIdNetworksErrorMessage}
                    unsavedChanges={unsavedChanges}
                    setIsSelectedGeolocationSettingUpdated={setIsSelectedGeolocationSettingUpdated}
                    onClickSave={onClickSave}
                    onClickDelete={onClickDelete}
                    saving={saving}
                    saveErrorMessage={saveErrorMessage}
                    saveSuccessMessage={saveSuccessMessage}
                    newClicked={newClicked}
                    userCanEdit={props.userCanEdit}
                    module={props.module}
                    service={props.service}
                    permissions={props.permissions}
                    userPermissions={props.userPermissions}
                    user={props.user}
                />
            </Grid.Column>
        </Grid>
    );
};
