import React, {useState, useEffect} from "react";
import {Button, Grid, Icon, Menu, Message} from "semantic-ui-react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

export default function TokenGeofencingArrangementStep(props) {
    const [importedGeofencesRemaining, setImportedGeofencesRemaining] = useState([]);
    const [geofencesToSave, setGeofencesToSave] = useState([]);

    useEffect(function initializeGeofencesToSave() {
        setGeofencesToSave(Object.values(props.allGeofences));
    }, [JSON.stringify(props.allGeofences)]);

    useEffect(function initializeImportedOverrides() {
        const updatedGeofencesRemaining = [];
        for (const geofence of Object.values(props.geofencesToImport)) {
            if (geofence.import) {
                updatedGeofencesRemaining.push(Object.assign({}, geofence.rule));
            }
        }
        setImportedGeofencesRemaining(updatedGeofencesRemaining);
    }, [JSON.stringify(props.geofencesToImport)]);

    const onDragEnd = result => {
        console.log("onDragEnd result: ", result);
        const {source, destination} = result;

        if (!destination || (source.index === destination.index)) {
            return;
        }

        if (source.droppableId === destination.droppableId && source.droppableId === "droppable-to-save") {
            const updatedGeofencesToSave = geofencesToSave.slice();
            const [removed] = updatedGeofencesToSave.splice(source.index, 1);
            updatedGeofencesToSave.splice(destination.index, 0, removed);
            setGeofencesToSave(updatedGeofencesToSave);
        } else if (source.droppableId !== destination.droppableId && destination.droppableId === "droppable-to-save") {
            const updatedImportedGeofencesRemaining = importedGeofencesRemaining.slice();
            const updatedGeofencesToSave = geofencesToSave.slice();

            const [removed] = updatedImportedGeofencesRemaining.splice(source.index, 1);
            updatedGeofencesToSave.splice(destination.index, 0, removed);

            setImportedGeofencesRemaining(updatedImportedGeofencesRemaining);
            setGeofencesToSave(updatedGeofencesToSave);
        }
    };

    return (
        <Grid className="masterContainer">
            <Grid.Row>
                <Grid.Column width={16}>
                    <Button primary icon floated="right" onClick={() => props.saveGeofences(geofencesToSave)}><Icon name="save" /> Save</Button>
                    <Button secondary icon floated="right" onClick={() => props.goBackClicked()}><Icon name="arrow left" /> Go Back</Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                {
                    props.saving ?
                        <Message icon color="blue">
                            <Icon name="spinner" loading />
                            <Message.Content>Saving...</Message.Content>
                        </Message> :
                    props.saveSuccessMessage ?
                        <Message icon color="green">
                            <Icon name="check" />
                            <Message.Content>{props.saveSuccessMessage}</Message.Content>
                        </Message> :
                    props.saveErrorMessage ?
                        <Message icon color="red">
                            <Icon name="x" />
                            <Message.Content>{props.saveErrorMessage}</Message.Content>
                        </Message> : ""
                }
            </Grid.Row>
            <Grid.Row>
                <Menu vertical fluid>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Menu vertical fluid>
                                        <Droppable droppableId="droppable-remaining">
                                            {
                                                (provided, snapshot) => {
                                                    console.log("provided: ", provided, "snapshot: ", snapshot);
                                                    return (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {
                                                                importedGeofencesRemaining.map((geofence, index) =>
                                                                    <Draggable key={`new-geofence-setting-${index}`} draggableId={`new-geofence-${index}`} index={index}>
                                                                        {
                                                                            provided => {
                                                                                console.log("provided: ", provided, "override: ", geofence);
                                                                                return (
                                                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{position: "static"}}>
                                                                                        <Menu.Item innerRef={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                            <Grid>
                                                                                                <Grid.Row verticalAlign="middle">
                                                                                                    <Grid.Column width={16}>
                                                                                                        <h5>{geofence.name}</h5>
                                                                                                        <>{geofence.description}</>
                                                                                                        {provided.placeholder}
                                                                                                    </Grid.Column>
                                                                                                </Grid.Row>
                                                                                            </Grid>
                                                                                        </Menu.Item>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }
                                                                    </Draggable>
                                                                )
                                                            }
                                                            {provided.placeholder}
                                                        </div>
                                                    );
                                                }
                                            }
                                        </Droppable>
                                    </Menu>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Droppable droppableId={"droppable-to-save"}>
                                        {
                                            provided =>
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {
                                                        geofencesToSave.map(
                                                            (setting, index) =>
                                                                <Draggable key={`current-geofence-setting-${index}`} draggableId={`current-geofence-${index}`} index={index}>
                                                                    {
                                                                        provided =>
                                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                <Menu.Item innerRef={provided.innerRef} {...provided.droppableProps} fluid>
                                                                                    <Grid>
                                                                                        <Grid.Row verticalAlign="middle">
                                                                                            <Grid.Column width={16}>
                                                                                                <h5>{setting.name}</h5>
                                                                                                <>{setting.description}</>
                                                                                                {provided.placeholder}
                                                                                            </Grid.Column>
                                                                                        </Grid.Row>
                                                                                    </Grid>
                                                                                </Menu.Item>
                                                                            </div>
                                                                    }
                                                                </Draggable>
                                                        )
                                                    }
                                                    {provided.placeholder}
                                                </div>
                                        }
                                    </Droppable>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </DragDropContext>
                </Menu>
            </Grid.Row>
        </Grid>
    );
};
